import React from 'react';
import { assets } from '../assetes';

const UserList = ({ users, setSelectedUser }) => {
  return (
    <div className="flex overflow-x-auto space-x-4 p-4 bg-gradient-to-r from-teal-50 to-indigo-50 rounded-xl shadow-inner">
      {users.map((user) => {
        const profile = user.profiledetails || {};
        return (
          <div
            key={user._id}
            className="group flex flex-col items-center text-center transform transition-all duration-300 hover:scale-105 hover:shadow-xl p-6 rounded-xl bg-white shadow-md border border-gray-200 hover:border-indigo-500 relative cursor-pointer"
            onClick={() => setSelectedUser(user)}
          >
            <div className="relative">
              <img
                src={profile.profilePic || assets.pfp}
                alt={profile.Username || 'User'}
                className="w-20 h-20 rounded-full border-4 border-indigo-300 shadow-md"
              />
            </div>
            <span className="mt-3 text-lg font-bold text-gray-900 group-hover:text-indigo-600">
              {profile.Username || user.email.split('@')[0]}
            </span>
            <span className="mt-1 text-xs text-gray-500 font-medium bg-indigo-100 px-3 py-1 rounded-full">
              {user.role || 'Role'}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default UserList;
