import React, { useEffect, useState } from 'react';
import { FaUserAlt, FaBriefcase, FaRegFileAlt } from 'react-icons/fa';

const JobSeekerProfile = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/Jobseekerprofile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profiles');
        }

        const data = await response.json();
        setProfiles(data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [token]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen text-lg text-gray-600">
        <p>Loading job seeker profiles, please wait...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 mt-5">
        <h2 className="text-xl">Oops! Something went wrong.</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
<div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
  <header className="text-center py-10">
    <h1 className="text-4xl font-bold text-teal-400">Meet Passionate Job Seekers</h1>
    <p className="text-gray-300 mt-4">
      Explore talented individuals ready to join your mission and make an impact!
    </p>
  </header>

  <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    {profiles.length === 0 ? (
      <p className="text-center text-gray-400 col-span-full">
        No job seekers found yet!
      </p>
    ) : (
      profiles.map((profile) => (
        <div
          key={profile._id}
          className="bg-gray-700 rounded-lg p-6 hover:scale-105 transition-transform shadow-lg flex flex-col items-center text-center"
        >
          {profile.profiledetails.profilePic ? (
            <img
              src={profile.profiledetails.profilePic}
              alt={`${profile.profiledetails.Username}'s profile`}
              className="w-24 h-24 rounded-full object-cover mb-4"
            />
          ) : (
            <div className="bg-gradient-to-r from-blue-500 to-teal-400 rounded-full w-24 h-24 flex items-center justify-center text-white mb-4">
              <FaUserAlt className="text-3xl" />
            </div>
          )}

          <h3 className="text-xl font-semibold text-teal-400">
            {profile.profiledetails.Username}
          </h3>

          <p className="text-sm text-gray-300 mt-2 italic">
            {profile.profiledetails.aboutMe || 'No description available'}
          </p>

          <div className="mt-4 space-y-2">
            <p className="text-sm font-medium text-gray-300">
              <FaBriefcase className="inline-block mr-2 text-teal-400" />
              <span>Experience:</span> {profile.profiledetails.experience || 'Not provided'}
            </p>
            <p className="text-sm font-medium text-gray-300">
              <FaRegFileAlt className="inline-block mr-2 text-teal-400" />
              <span>Desired Role:</span> {profile.profiledetails.desiredRole || 'Not specified'}
            </p>
          </div>

          <div className="mt-4">
            <p className="font-semibold text-teal-400">Skills:</p>
            <ul className="list-disc pl-5 text-gray-300">
              {Array.isArray(profile.profiledetails.skills) && profile.profiledetails.skills.length > 0 ? (
                profile.profiledetails.skills.map((skill, index) => <li key={index}>{skill}</li>)
              ) : (
                <li>No skills listed</li>
              )}
            </ul>
          </div>
          
          <button className="mt-6 w-full bg-teal-500 hover:bg-teal-600 text-white font-semibold py-2 px-4 rounded-lg">
            Connect
          </button>
        </div>
      ))
    )}
  </div>
</div>

  );
};

export default JobSeekerProfile;
