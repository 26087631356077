import React from 'react'
import Dashboard from '../../componentes/Dashboard'
import Cofoundernavbar from '../../componentes/CofounderNavbar'

const InvestorDashboard = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex-grow">
        <Dashboard />
      </div>
      <Cofoundernavbar/>
    </div>
  )
}

export default InvestorDashboard