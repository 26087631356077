import React, { useEffect, useState } from "react";
import UserList from "./UserList";
import ChatPopup from "./chatpopup";
import ChatUI from "./chatui";

const MessageTop = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://dattatreya-back.onrender.com/api/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          console.error("Unexpected API response format:", data);
          setError("Unexpected response format");
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setError("Error fetching users");
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="relative w-full h-auto bg-gradient-to-r from-teal-100 via-blue-100 to-indigo-100 shadow-2xl">
      <h2 className="text-3xl font-extrabold text-center text-gray-800 pt-4 tracking-wide">
        Talk to Others
      </h2>
      {loading ? (
        <p className="text-gray-500 text-center text-lg">Loading users...</p>
      ) : error ? (
        <p className="text-red-500 text-center text-lg">{error}</p>
      ) : users.length > 0 ? (
        <UserList users={users} setSelectedUser={setSelectedUser} />
      ) : (
        <p className="text-gray-500 text-center text-lg">No users available</p>
      )}

      <div className=" relative left-6 w-full h-auto overflow-y-auto bg-transparent shadow-lg">
        <ChatUI userId={selectedUser ? selectedUser._id : null} />
      </div>
      {selectedUser && <ChatPopup selectedUser={selectedUser} />}
    </div>
  );
};

export default MessageTop;
