import React, { useState } from 'react';
import axios from 'axios';
import { assets } from '../../assetes';
import { useNavigate } from 'react-router-dom';

const Investor = () => {
  const [formData, setFormData] = useState({
    Username: '',
    phoneno: '',
    bio: '',
    contactEmail: '',
    adventureAlert: 5,
    investorType: '',
    interestAreas: [],
    explorationPreference: 5,
    excitement: '',
    profilePic: null,
  });
  const [profilePicPreview, setProfilePicPreview] = useState(null); // State for profile picture preview

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === 'checkbox') {
      setFormData((prevData) => {
        const updatedInterests = checked
          ? [...prevData.interestAreas, value]
          : prevData.interestAreas.filter((interest) => interest !== value);

        return {
          ...prevData,
          interestAreas: updatedInterests,
        };
      });
    } else if (type === 'file') {
      const file = files[0]; // Access the selected file
      setFormData((prevData) => ({
        ...prevData,
        profilePic: file,
      }));

      // Create a preview URL for the image
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicPreview(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
        setProfilePicPreview(null); // Clear preview if no file is selected
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === 'profilePic' && formData[key]) {
        formDataToSend.append(key, formData[key]);
      } else if (key !== 'profilePic') {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        'https://dattatreya-back.onrender.com/api/userprofile/Investor',
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log(response.data);
      alert('Profile updated successfully!');
      navigate('/signin');
    } catch (error) {
      console.error('Error submitting form data', error);
      alert('There was an error submitting your data.');
    }
  };
  const handleSkip = () =>{
    navigate('/dashboard/Investor'); 
  }

  return (
    <div className="relative min-h-screen bg-gray-100 text-gray-800 flex flex-col items-center py-12 px-6">
      <div className="absolute top-0 left-0 m-6">
        <img src={assets.Logo} alt="datatreya Logo" className="h-12" />
      </div>
      <div className="absolute top-0 right-0 m-6">
      <button
        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300"
        onClick={handleSkip} // Ensure `handleSkip` navigates the user appropriately
      >
        Skip for Now
      </button>
    </div>
      <div className="w-full max-w-4xl">
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mt-20">
            Join the datatreya Adventure!
          </h1>
          <p className="text-lg text-gray-600">
            Welcome, Trailblazers! We're on a quest to find visionary
            investors who want to embark on an exciting journey with datatreya.
            Let's see if you have what it takes to be a part of our mission to
            connect ordinary people with extraordinary ideas! pls create your profile.
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded-lg shadow-md grid grid-cols-1 gap-6 sm:grid-cols-2"
        >
          <div className="col-span-2 flex flex-col items-center">
            {profilePicPreview ? (
              <img
                src={profilePicPreview}
                alt="Profile Preview"
                className="w-32 h-32 rounded-full object-cover border-4 border-blue-500"
              />
            ) : (
              <div className="w-32 h-32 rounded-full bg-blue-100 flex items-center justify-center text-gray-500">
                No Image
              </div>
            )}
            <input
              type="file"
              id="profilePic"
              className="mt-4 text-sm"
              accept="image/*"
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="Username">What should we call you, Brave Investor?</label>
            <input
              type="text"
              id="Username"
              name="Username"
              value={formData.Username}
              onChange={handleChange}
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Your Full Name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="contactEmail">Where can we send your treasure map?</label>
            <input
              type="email"
              id="contactEmail"
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="you@example.com"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="phoneno">How can we reach you in case of an adventure alert?</label>
            <input
              type="tel"
              id="phoneno"
              name="phoneno"
              value={formData.phoneno}
              onChange={handleChange}
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="+000 0000000000"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="investorType">What type of investor are you?</label>
            <select
              id="investorType"
              name="investorType"
              value={formData.investorType}
              onChange={handleChange}
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="visionary">The Visionary (Angel Investor)</option>
              <option value="wealthWizard">The Wealth Wizard (Venture Capitalist)</option>
              <option value="growthGuru">The Growth Guru (Institutional Investor)</option>
              <option value="communityChampion">The Community Champion (Crowdfunding Enthusiast)</option>
              <option value="curious">Just Curious!</option>
            </select>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-600 mb-2">What areas interest you most?</label>
            <div className="flex flex-wrap gap-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="Sustainable Innovations"
                  checked={formData.interestAreas.includes('Sustainable Innovations')}
                  onChange={handleChange}
                  className="form-checkbox text-blue-600"
                />
                <span className="ml-2">Sustainable Innovations</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="Tech and App Development"
                  checked={formData.interestAreas.includes('Tech and App Development')}
                  onChange={handleChange}
                  className="form-checkbox text-blue-600"
                />
                <span className="ml-2">Tech and App Development</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="Creative Arts & Media"
                  checked={formData.interestAreas.includes('Creative Arts & Media')}
                  onChange={handleChange}
                  className="form-checkbox text-blue-600"
                />
                <span className="ml-2">Creative Arts & Media</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="Social Impact Projects"
                  checked={formData.interestAreas.includes('Social Impact Projects')}
                  onChange={handleChange}
                  className="form-checkbox text-blue-600"
                />
                <span className="ml-2">Social Impact Projects</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="Something Else"
                  checked={formData.interestAreas.includes('Something Else')}
                  onChange={handleChange}
                  className="form-checkbox text-blue-600"
                />
                <span className="ml-2"> Something Else (Tell us!)</span>
              </label>
            </div>
          </div>
          <div className="col-span-2">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white p-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit Profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Investor;
