import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const UpdateProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state?.user || {}; 
  const token = localStorage.getItem('token');

  const [formData, setFormData] = useState({
    Username: user?.profiledetails?.Username || '',
    phoneno: user?.profiledetails?.phoneno || '',
    bio: user?.profiledetails?.bio || '',
    project: user?.profiledetails?.project || '',
    currentStage: user?.profiledetails?.currentStage || 'Ideation',
    skills: Array.isArray(user?.profiledetails?.skills) ? user.profiledetails.skills.join(', ') : '',
    currentWork: user?.profiledetails?.currentWork || '',
    contribution: user?.profiledetails?.contribution || '',
  });

  const [profilePic, setProfilePic] = useState(null); // State for profile pic
  const [preview, setPreview] = useState(null); // State for image preview

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfilePic(file); // Store selected file
    if (file) {
      const filePreview = URL.createObjectURL(file);
      setPreview(filePreview); 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    if (profilePic) {
      formDataToSend.append('profilePic', profilePic); 
    }

    try {
      const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/Update_H_profile', {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });

      const data = await response.json();

      if (data.success) {
        alert('Profile updated successfully!');
        navigate('/profile'); 
      } else {
        throw new Error(data.error || 'Failed to update profile');
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-lg rounded-lg p-6 w-full max-w-lg"
        encType="multipart/form-data"
      >
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Update Profile</h2>

        <div className="mb-4">
          <label className="block text-gray-700">Profile Picture</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full p-2 border rounded"
          />
          {preview && (
            <div className="mt-4">
              <img
                src={preview}
                alt="Profile Preview"
                className="w-32 h-32 object-cover rounded-full border"
              />
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Username</label>
          <input
            type="text"
            name="Username"
            value={formData.Username}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Phone Number</label>
          <input
            type="text"
            name="phoneno"
            value={formData.phoneno}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Bio</label>
          <textarea
            name="bio"
            value={formData.bio}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Project</label>
          <input
            type="text"
            name="project"
            value={formData.project}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Current Stage</label>
          <select
            name="currentStage"
            value={formData.currentStage}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="Ideation">Ideation</option>
            <option value="Development">Development</option>
            <option value="Launch">Launch</option>
            <option value="Growth">Growth</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Skills (comma separated)</label>
          <input
            type="text"
            name="skills"
            value={formData.skills}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Current Work</label>
          <textarea
            name="currentWork"
            value={formData.currentWork}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Contribution</label>
          <textarea
            name="contribution"
            value={formData.contribution}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded-lg w-full hover:bg-blue-700"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default UpdateProfile;
