import React, { useState, useEffect } from 'react';

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      // Check for token in localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found, please log in.');
        setLoading(false);
        return;
      }

      try {
        // Make the API call with token in headers
        const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/getblogs', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Include token in the request
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.success) {
          setBlogs(data.data);
        } else {
          throw new Error('Failed to fetch blogs.');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching the blogs.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <div className="p-6 text-white">Loading blogs...</div>;
  }

  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-4">Blogs</h2>
      <div className="space-y-4">
        {blogs.map((blog) => (
          <div key={blog._id} className="bg-gray-800 text-white p-4 rounded-lg">
            <img
              src={blog.coverImageUrl}
              alt={blog.title}
              className="rounded-lg mb-4 w-full h-48 object-cover"
            />
            <h3 className="text-lg font-bold">{blog.title}</h3>
            <p className="text-sm text-teal-400">By {blog.uploadedBy?.profiledetails?.Username || 'Unknown'}</p>
            <p className="mt-2">{blog.summary}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogsPage;
