import React, { useEffect, useState } from "react";
import axios from "axios";
import ChatPopup from "./chatpopup";
import { assets } from "../assetes";

const ChatUI = ({ userId }) => {
  const [chats, setChats] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axios.get("https://dattatreya-back.onrender.com/api/messages", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setChats(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchChats();
  }, []);

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Your Chats</h2>
      <div className="flex flex-col gap-5 mb-5 max-w-md">
        {chats.length > 0 ? (
          chats.map((chat) => (
            <div
              key={chat._id}
              className="flex items-center p-4 bg-white shadow-lg rounded-lg cursor-pointer mr-auto"
              onClick={() => setSelectedUser(chat)}
            >
              <img
                src={chat.profilePic || assets.pfp}
                alt="User"
                className="w-12 h-12 rounded-full mr-4 object-cover"
              />
              <div>
                <h4 className="text-lg font-semibold text-gray-700">{chat.email}</h4>
                <p className="text-sm text-gray-500">{chat.text || "[No Message]"}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No previous chats found</p>
        )}
      </div>
      
      {selectedUser && <ChatPopup selectedUser={selectedUser} />}
    </div>
  );
};

export default ChatUI;
