import React, { useEffect, useState } from 'react';
import { FaUser, FaRocket, FaRegStar, FaConnectdevelop } from 'react-icons/fa';

const HustlerProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/Hustlerprofile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch profiles');
        }

        const data = await response.json();
        setProfiles(data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [token]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen text-lg text-gray-600">
        <p>Loading profiles, please wait...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 mt-5">
        <h2 className="text-xl">Oops! Something went wrong.</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <header className="text-center py-10">
        <h1 className="text-4xl font-bold text-teal-400">Explore Startups</h1>
        <p className="text-gray-300 mt-4">
          Discover top hustlers and their innovative projects shaping the future.
        </p>
      </header>

      <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {profiles.length === 0 ? (
          <p className="text-center text-gray-400 col-span-full">
            No startups found yet!
          </p>
        ) : (
          profiles.map((profile) => (
            <div
              key={profile._id}
              className="bg-gray-700 rounded-lg p-6 hover:scale-105 transform transition-transform shadow-lg flex flex-col justify-between"
            >
              <div className="flex flex-col items-center text-center">
                <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-800 flex items-center justify-center mb-4">
                  {profile.profiledetails.profilePic ? (
                    <img
                      src={profile.profiledetails.profilePic}
                      alt={`${profile.profiledetails.Username}'s profile`}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <FaUser className="text-4xl text-teal-400" />
                  )}
                </div>
                <h2 className="text-2xl font-bold text-teal-400">
                  {profile.profiledetails.Username}
                </h2>
                <p className="text-sm text-gray-300 italic mt-2">
                  {profile.profiledetails.bio || 'Passionate entrepreneur'}
                </p>
              </div>
              <div className="mt-6">
                <p className="text-lg font-semibold text-teal-500 mb-2">
                  <FaRocket className="inline-block mr-2" />
                  Project: {profile.profiledetails.project || 'Not specified'}
                </p>
                <p className="text-lg font-semibold text-teal-500">
                  <FaRegStar className="inline-block mr-2" />
                  Stage: {profile.profiledetails.currentStage || 'Not specified'}
                </p>
              </div>
              <div className="mt-4">
                <p className="font-semibold text-teal-500 mb-2">Skills:</p>
                <ul className="list-disc pl-5 text-gray-300">
                  {Array.isArray(profile.profiledetails.skills) && profile.profiledetails.skills.length > 0 ? (
                    profile.profiledetails.skills.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))
                  ) : (
                    <li>No skills listed</li>
                  )}
                </ul>
              </div>
              <div className="mt-4 text-sm text-gray-300 space-y-2">
                <p>
                  <strong>Current Work:</strong>{' '}
                  {profile.profiledetails.currentWork || 'Not specified'}
                </p>
                <p>
                  <strong>Contribution:</strong>{' '}
                  {profile.profiledetails.contribution || 'Not specified'}
                </p>
              </div>
              <button className="mt-6 bg-teal-500 hover:bg-teal-600 text-white font-semibold py-2 px-4 rounded-lg flex items-center justify-center">
                <FaConnectdevelop className="mr-2" />
                Connect
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HustlerProfiles;
