import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { assets } from '../../assetes';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/getUser', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user profile');
        }

        const data = await response.json();
        if (data.success) {
          setUser(data.user);
        } else {
          throw new Error(data.message || 'Failed to fetch user profile');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [token]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-lg font-medium text-gray-600 animate-pulse">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-lg font-medium text-red-500">Error: {error}</div>
      </div>
    );
  }
  console.log(user);
  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 flex items-center justify-center px-4 py-8">
      <div className="bg-white shadow-2xl rounded-lg w-full max-w-3xl p-8 transform transition duration-300 hover:scale-105">
      <div className="flex flex-col md:flex-row items-center gap-6">
       <img
        src={user?.profiledetails?.profilePic || assets.pfp}
        alt="Profile"
       className="w-36 h-36 md:w-44 md:h-44 rounded-full object-cover border-4 border-blue-400 shadow-lg"
      />
      <div className="text-center md:text-left flex-1">
    <h1 className="text-3xl font-bold text-gray-900">
      {user?.profiledetails?.Username || 'N/A'}
      {user?.verified && (
        <img
          src={assets.Badge} 
          alt="Verified Badge"
          className="inline-block ml-2 mt-2 w-10 h-12"
        />
      )}
    </h1>
    <p className="text-gray-600 text-sm italic mt-1">{user?.profiledetails?.bio || 'No bio available'}</p>
    <div className="mt-4 space-y-2 text-sm text-gray-700">
      <p><strong>Email:</strong> {user?.email || 'N/A'}</p>
      <p><strong>Project:</strong> {user?.profiledetails?.project || 'N/A'}</p>
      <p><strong>Current Stage:</strong> {user?.profiledetails?.currentStage || 'N/A'}</p>
      <p><strong>Role:</strong> {user?.role || 'N/A'}</p>
      <p><strong>Contribution:</strong> {user?.profiledetails?.contribution || 'N/A'}</p>
    </div>
  </div>
</div>

        <div className="mt-6">
          <h2 className="text-lg font-semibold text-gray-800">Skills</h2>
          {user?.profiledetails?.skills ? (
            Array.isArray(user.profiledetails.skills) && user.profiledetails.skills.length > 0 ? (
              <div className="flex flex-wrap gap-2 mt-2">
                {user.profiledetails.skills.map((skill, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 text-sm bg-blue-200 text-blue-800 rounded-full shadow-md hover:bg-blue-300 transition duration-200"
                  >
                    {skill.trim()}
                  </span>
                ))}
              </div>
            ) : (
              <p className="text-gray-600 text-sm mt-2">{typeof user.profiledetails.skills === 'string' ? user.profiledetails.skills : 'No skills added yet.'}</p>
            )
          ) : (
            <p className="text-gray-600 text-sm mt-2">No skills added yet.</p>
          )}
        </div>
        <div className="mt-6 flex justify-center">
          <button
            onClick={() => navigate('/Update_profile', { state: { user } })}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700 transition duration-200 transform hover:scale-105"
          >
            Update Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
