import React, { useState } from "react";
import axios from "axios";

const BlogsUploadPopup = ({ isOpen, closePopup }) => {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!title || !summary || !content) {
      setError("Please provide a title, summary, and content. 🛑");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("summary", summary);
    formData.append("content", content); 
    if (file) formData.append("image", file);

    try {
      const response = await axios.post(
        "https://dattatreya-back.onrender.com/api/userprofile/addblog",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data && response.data.message) {
        setMessage("🚀 Your blog summary has been uploaded successfully! Your ideas matter.");
        setError(null);
        setTitle("");
        setSummary("");
        setContent("");
        setFile(null);

        setTimeout(() => {
          closePopup();
        }, 2000);
      } else {
        setError("Something went wrong, please try again. 😓");
      }
    } catch (error) {
      console.error(error);
      setError(error.response ? error.response.data.error : "An unexpected error occurred. 😱");
      setMessage(null);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center animate-fadeIn">
      <div className="bg-white p-8 rounded-lg w-11/12 md:w-1/3 shadow-lg transform transition-transform duration-300 ease-in-out scale-105 hover:scale-100">
        <h2 className="text-3xl font-bold text-center text-teal-600 mb-6 tracking-wide">
          Share Your Insights with a Brief Summary ✍️
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <label className="block font-semibold text-gray-700">Title of Your Blog 🏆</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-3 border border-teal-300 rounded-md shadow-sm focus:ring-2 focus:ring-teal-400"
              placeholder="Enter a catchy title"
            />
          </div>
          <div className="mb-5">
            <label className="block font-semibold text-gray-700">Brief Summary 📝</label>
            <textarea
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              className="w-full p-3 border border-teal-300 rounded-md shadow-sm focus:ring-2 focus:ring-teal-400"
              placeholder="Summarize your blog in a few sentences"
              rows="4"
            />
          </div>
          <div className="mb-5">
            <label className="block font-semibold text-gray-700">Content of Your Blog 🖊️</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full p-3 border border-teal-300 rounded-md shadow-sm focus:ring-2 focus:ring-teal-400"
              placeholder="Write the full content of your blog"
              rows="6"
            />
          </div>
          <div className="mb-5">
            <label className="block font-semibold text-gray-700">Optional Cover Image 📸</label>
            <input
              type="file"
              onChange={handleFileChange}
              accept="image/png, image/jpeg, image/jpg"
              className="w-full p-3 border border-teal-300 rounded-md cursor-pointer"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-teal-500 text-white p-3 rounded-md hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-300 transition duration-300"
          >
            Publish Blog Summary! 🌟
          </button>
        </form>
        {error && <div className="mt-4 text-red-500 text-center font-medium">{error}</div>}
        {message && <div className="mt-4 text-green-500 text-center font-medium">{message}</div>}
        <button
          onClick={closePopup}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default BlogsUploadPopup;
