import React, { useState, useEffect } from 'react';
import { FaBook, FaEdit, FaHandshake, FaMoneyBillWave, FaUsers, FaBell, FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import VideoUploadPopup from './CreateVideos';
import BlogsUploadPopup from './CreateBlogs';
import { assets } from '../assetes';

const Hustlernavbar = () => {
  const [isContentsOpen, setIsContentsOpen] = useState(false);
  const [isCreateContentsOpen, setIsCreateContentsOpen] = useState(false);
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
  const [isBlogPopupOpen, setIsBlogPopupOpen] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    fetch('https://dattatreya-back.onrender.com/api/userprofile/getUser', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.user.profiledetails.profilePic) {
          setProfilePic(data.user.profiledetails.profilePic || assets.pfp);
          console.log("Profile Pic URL:", data.user.profiledetails.profilePic || assets.pfp);
        }
      })
      .catch((error) => {
        console.error('Error fetching profile data:', error);
      });
  }, []);

  const toggleContentsDropdown = () => {
    setIsContentsOpen(!isContentsOpen);
    if (isCreateContentsOpen) setIsCreateContentsOpen(false);
  };

  const toggleCreateContentsDropdown = () => {
    setIsCreateContentsOpen(!isCreateContentsOpen);
    if (isContentsOpen) setIsContentsOpen(false);
  };

  const openVideoPopup = () => {
    setIsVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setIsVideoPopupOpen(false);
  };

  const openBlogPopup = () => {
    setIsBlogPopupOpen(true);
  };

  const closeBlogPopup = () => {
    setIsBlogPopupOpen(false);
  };

  return (
    <div>
      <div className="w-64 bg-gradient-to-b from-gray-800 to-gray-900 text-white p-5 sticky top-0 h-screen rounded-lg shadow-lg flex-shrink-0">
        <div className="mb-10 text-center">
          <h1 className="text-2xl font-bold uppercase tracking-wide text-teal-400">
            datatreya
          </h1>
        </div>
        <ul className="flex flex-col h-full">
          <div className="space-y-4 flex-1">
            <li className="flex flex-col" onClick={toggleContentsDropdown}>
              <div className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all">
                <FaBook className="text-teal-400" />
                <span>Contents</span>
              </div>
              {isContentsOpen && (
                <ul className="pl-8 space-y-2 mt-2">
                  <li className="hover:bg-gray-700 p-2 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/videos')}>
                    Videos
                  </li>
                  <li className="hover:bg-gray-700 p-2 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/blogs')}>
                    Blogs
                  </li>
                  <li className="hover:bg-gray-700 p-2 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/talks')}>
                    Talks
                  </li>
                </ul>
              )}
            </li>
            <li className="flex flex-col" onClick={toggleCreateContentsDropdown}>
              <div className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all">
                <FaEdit className="text-teal-400" />
                <span>Create Contents</span>
              </div>
              {isCreateContentsOpen && (
                <ul className="pl-8 space-y-2 mt-2">
                  <li className="hover:bg-gray-700 p-2 rounded-lg cursor-pointer transition-all" onClick={openVideoPopup}>
                    Create Videos
                  </li>
                  <li className="hover:bg-gray-700 p-2 rounded-lg cursor-pointer transition-all" onClick={openBlogPopup}>
                    Create Blogs
                  </li>
                </ul>
              )}
            </li>

            <li className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/Findcofounder')}>
              <FaHandshake className="text-teal-400" />
              <span>Explore Co-founder Profiles</span>
            </li>
            <li className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/FindInvestor')}>
              <FaMoneyBillWave className="text-teal-400" />
              <span>Explore Investor Profiles</span>
            </li>
            <li className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/FindJobseeker')}>
              <FaUsers className="text-teal-400" />
              <span>Find Intern Profiles</span>
            </li>
            <li className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/messages')}>
              <FaEnvelope className="text-teal-400" />
              <span>Messages</span>
            </li>

            <li className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/Notification')}>
              <FaBell className="text-teal-400" />
              <span>Notifications</span>
            </li>

            <li className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all" onClick={() => navigate('/Profile')}>
              <div className="ml-2">
                {profilePic ? (
                  <img src={profilePic} alt="Profile" className="w-8 h-8 rounded-full" />
                ) : (
                  <div className="w-8 h-8 rounded-full bg-gray-600 flex items-center justify-center">
                    <span className="text-white text-xs">No Pic</span>
                  </div>
                )}
              </div>
              <span>Profile</span>
            </li>
          </div>
        </ul>
      </div>
      <BlogsUploadPopup isOpen={isBlogPopupOpen} closePopup={closeBlogPopup} />
      <VideoUploadPopup isOpen={isVideoPopupOpen} closePopup={closeVideoPopup} />
    </div>
  );
};

export default Hustlernavbar;
