import React, { useEffect, useState } from 'react';

const VideosPage = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found, please log in.');
          setLoading(false);
          return;
        }
        const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/getvideos', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok && data.success) {
          setVideos(data.data); 
        } else {
          setError(data.message || 'Failed to load videos.');
        }
      } catch (err) {
        setError('An error occurred while fetching videos.');
        console.error('Fetch error:', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-4">Videos</h2>
      {loading && <p className="text-gray-500">Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && videos.length === 0 && (
        <p className="text-gray-500">No videos available.</p>
      )}
      <div className="grid grid-cols-3 gap-4">
        {!loading &&
          !error &&
          videos.map((video) => (
            <div key={video._id} className="bg-gray-800 text-white p-4 rounded-lg">
              <video
                src={video.videoUrl}
                controls
                className="mb-3 w-full rounded-lg"
                style={{ aspectRatio: '16/9' }}
              />
              <h3 className="text-lg font-semibold">{video.title}</h3>
              <p className="text-sm text-gray-400">
              <img
                src={video.uploadedBy.profiledetails?.profilePic}
                alt="Avatar"
                className="w-7 h-7 rounded-full mr-4"
              />
                <p className="font-semibold text-white">{video.uploadedBy.profiledetails?.Username}</p>
              </p>
              <p className="text-sm text-gray-500 mt-1">{video.description}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VideosPage;
