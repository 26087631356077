import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { assets } from '../assetes';
import HeroSection from '../componentes/HeroSection';
import Features from '../componentes/Features';
import { Menu, X } from 'lucide-react';

const Home = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 via-gray-100 to-gray-300 p-6">
      <header className="w-full flex justify-between items-center py-4 px-8 bg-white shadow-md sticky top-0 z-50 border-b border-gray-200">
        <div className="flex items-center gap-4">
          <img src={assets.Logo} alt="datatreya Logo" className="h-14" />
        </div>
        
        {/* Desktop Nav */}
        <nav className="hidden md:flex items-center gap-6">
          <button className="text-gray-700 text-lg font-medium hover:text-indigo-600 transition duration-300" onClick={()=>navigate('/AboutUs')}>
            About Us
          </button>
          <button className="text-gray-700 text-lg font-medium hover:text-indigo-600 transition duration-300" onClick={()=>navigate('/Services')}>
            Services
          </button>
          <button className="text-gray-700 text-lg font-medium hover:text-indigo-600 transition duration-300">
            Contact
          </button>
          <button onClick={() => navigate('/signin')} className="px-6 py-2 text-white bg-indigo-600 rounded-full shadow-md hover:bg-indigo-700 transition duration-300">
            Sign In
          </button>
        </nav>

        {/* Mobile Menu Button */}
        <button className="md:hidden text-gray-700" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
        </button>
      </header>

      {/* Mobile Menu Popup */}
      {isMenuOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-sm text-center relative">
            <button className="absolute top-4 right-4" onClick={() => setIsMenuOpen(false)}>
              <X size={24} className="text-gray-700" />
            </button>
            <nav className="flex flex-col gap-4 mt-4">
              <button className="text-gray-700 text-lg font-medium hover:text-indigo-600 transition duration-300" onClick={()=>navigate('/AboutUs')}>
                About Us
              </button>
              <button className="text-gray-700 text-lg font-medium hover:text-indigo-600 transition duration-300" onClick={()=>navigate('/Services')}>
                Services
              </button>
              <button className="text-gray-700 text-lg font-medium hover:text-indigo-600 transition duration-300">
                Contact
              </button>
              <button onClick={() => navigate('/signin')} className="px-6 py-2 text-white bg-indigo-600 rounded-full shadow-md hover:bg-indigo-700 transition duration-300">
                Sign In
              </button>
            </nav>
          </div>
        </div>
      )}

      <HeroSection/>
      <Features/>
      
      <section className="mt-16 max-w-4xl mx-auto text-center px-6 sm:px-10">
        <h2 className="text-4xl sm:text-5xl font-extrabold text-gray-800 leading-tight">
          Why <span className="text-[#8BBEB2]">Join datatreya?</span>
        </h2>
        <p className="mt-4 text-lg sm:text-xl text-gray-700">
          More than just a platform—datatreya empowers innovators with mentorship, funding, 
          market validation, and a thriving community of like-minded pioneers.
        </p>
        <div className="mt-8 flex flex-wrap justify-center gap-6">
          {[
            { title: "🚀 Mentorship", desc: "Learn from industry leaders and experienced entrepreneurs to accelerate your journey." },
            { title: "💰 Funding", desc: "Get connected with investors who believe in your vision and help bring your ideas to life." },
            { title: "📈 Market Validation", desc: "Test your ideas with real consumers and refine them with valuable insights." },
            { title: "🌎 Strong Network", desc: "Join a powerful ecosystem of innovators, entrepreneurs, and visionaries." }
          ].map((item, index) => (
            <div key={index} className="max-w-sm p-6 bg-white shadow-lg rounded-xl">
              <h3 className="text-2xl font-semibold text-gray-800">{item.title}</h3>
              <p className="text-gray-600 mt-2">{item.desc}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="mt-16 max-w-2xl text-center">
        <h2 className="text-3xl font-bold text-gray-800">Meet the Team</h2>
        <p className="mt-4 text-lg font-medium text-gray-700">
          <a href="mailto:saharanit16@gmail.com" className="text-blue-500 font-semibold hover:underline">Ranit Saha</a> - CEO
        </p>
        <p className="text-lg font-medium text-gray-700">
          <a href="mailto:niladrihowladar2@gmail.com" className="text-green-500 hover:underline font-semibold">Niladri Howladar</a> - COO
        </p>
      </section>

      <section className="mt-16 max-w-4xl text-center">
        <h2 className="text-3xl font-bold text-gray-800">Frequently Asked Questions</h2>
        <div className="mt-4 space-y-4">
          {[{ question: "What is datatreya?", answer: "An ecosystem connecting innovators and investors." },
            { question: "Can I collaborate?", answer: "Yes, connect with innovators and build a strong team." },
            { question: "How do I get started?", answer: "Sign up, create a profile, and showcase your ideas." }
          ].map((faq, index) => (
            <details key={index} className="p-4 bg-gray-100 rounded-lg shadow-md cursor-pointer">
              <summary className="font-semibold text-indigo-600">{faq.question}</summary>
              <p className="mt-2 text-gray-700">{faq.answer}</p>
            </details>
          ))}
        </div>
      </section>

      <footer className="mt-16 text-gray-600 text-center text-sm">
        © {new Date().getFullYear()} datatreya. All rights reserved.
      </footer>
    </div>
  );
};

export default Home;