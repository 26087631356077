import React from 'react'
import Messagetop from '../componentes/messagetop';

const messages = () => {
  return (
    <div>
    <Messagetop/>
    </div>
  )
}

export default messages