import React, { useState } from 'react';
import { assets } from '../assetes';
import { useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    posthog.capture('signin_attempt', { method: 'email' });

    try {
      const response = await fetch('https://dattatreya-back.onrender.com/api/auth/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      setIsLoading(false);

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Sign-in failed. Please try again.');
        return;
      }

      const data = await response.json();
      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('userId', data.id); 

      console.log('Sign-in successful', data);
      const verifyResponse = await fetch(' https://dattatreya-back.onrender.com/api/user/verify', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (verifyResponse.ok) {
        const verifyData = await verifyResponse.json();
        console.log('User verification result:', verifyData.message);
      } else {
        const verifyErrorData = await verifyResponse.json();
        console.error('Verification failed:', verifyErrorData.message);
      }

      switch (data.role) {
        case 'Hustler':
          navigate('/dashboard/Hustler');
          break;
        case 'Investor':
          navigate('/dashboard/Investor');
          break;
        case 'Cofounder':
          navigate('/dashboard/Cofounder');
          break;
        case 'Jobseeker':
          navigate('/dashboard/Jobseeker');
          break;
        default:
          navigate('/');
          break;
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
      setErrorMessage('An error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#a3c8fb] via-[#c9dcfc] to-white">
        <div className="text-center">
          <img
            src={assets.Logo}
            alt="datatreya Logo"
            className="h-20 w-20 mb-4 animate-bounce"
          />
          <p className="text-lg font-bold text-[#009ff5]">
            Hang tight! We're connecting you to the awesomeness. 🚀
          </p>
          <p className="text-gray-600 italic">
            (First-time load might take a moment. Grab some chai! ☕)
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#a3c8fb] via-[#c9dcfc] to-white">
      <img
        src={assets.Mtbg}
        alt="background"
        className="absolute w-screen h-[401px] object-cover opacity-80 mt-96"
      />
      <div className="absolute top-0 left-0 m-6">
        <img src={assets.Logo} alt="datatreya Logo" className="h-10" />
      </div>

      <div className="relative w-full max-w-lg bg-white p-8 rounded-lg shadow-md z-10">
        <div className="text-center mb-8">
          <h1 className="text-3xl text-left font-bold text-[#009ff5]">Sign In</h1>
          <p className="text-gray-600 text-left">See what is going on with your business</p>
        </div>
        <p className="text-center mb-8 text-lg">--------or use your email--------</p>
        {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}

        <form onSubmit={handleSignin}>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#009ff5]"
              placeholder="mail@abc.com"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#009ff5]"
              placeholder="*****"
              required
            />
          </div>

          <div className="flex items-center justify-between mb-6">
            <label className="flex items-center"></label>
            <a href="#" className="text-[#009ff5]">
              Forgot Password?
            </a>
          </div>

          <button
            type="submit"
            className="w-full bg-[#009ff5] text-white py-2 rounded-md hover:bg-[#0086d9]"
          >
            Login
          </button>
        </form>

        <div className="text-center mt-6">
          <p className="text-gray-600">
            Not Registered Yet?{' '}
            <a href="/signup" className="text-[#009ff5]">
              Create an account
            </a>
          </p>
        </div>
      </div>
      <div className="mt-6 text-center text-gray-500 relative z-10">
        <p>Copyright ©2025 Produced by datatreya & Co.</p>
      </div>
    </div>
  );
};

export default Signin;
