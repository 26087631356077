import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import posthog from 'posthog-js';

// Import your pages
import Home from './Pages/Home';
import Signup from './Pages/SignUp';
import Signin from './Pages/SignIn';
import Hustlers from './Pages/formes/Hustler';
import Cofunder from './Pages/formes/Cofounder';
import Investor from './Pages/formes/Investor';
import Jobseeker from './Pages/formes/Jobseeker';
import HustlerDashboard from './Pages/Dashboards/HustlerDashboard';
import CofunderDashboard from './Pages/Dashboards/CofunderDashboard';
import JobseekerDashboard from './Pages/Dashboards/JobseekerDashboard';
import InvestorDashboard from './Pages/Dashboards/InvestorDashboard';
import VideosPage from './Pages/Video';
import BlogsPage from './Pages/Blog';
import TalksPage from './Pages/talks';
import Findcofounder from './Pages/Findcofounder';
import FindHustler from './Pages/FindHustler';
import FindInvestor from './Pages/FindInvestor';
import FindJobseeker from './Pages/FindJobseeker';
import Profile from './Pages/profiles/Profile';
import Notification from './Pages/notification';
import UpdateProfile from './Pages/updateprofiles/HuslerUprofile';
import Messages from './Pages/messages';
import AboutUs from './Pages/Aboutus';
import Services from './Pages/Service';

posthog.init('phc_XY6d42OoVaJmXNf7y70SFsAU094ZMBbu1zqOqWFINrX', {
  api_host: 'https://us.i.posthog.com', // Change if using self-hosted PostHog
  autocapture: true, // Auto-track clicks, pageviews, etc.
  capture_pageview: true, // Tracks page views automatically
});

function App() {
  // Track page views manually (optional)
  React.useEffect(() => {
    posthog.capture('$pageview');
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/HustlerForm" element={<Hustlers />} />
          <Route path="/CofunderForm" element={<Cofunder />} />
          <Route path="/InvestorForm" element={<Investor />} />
          <Route path="/JobseekerForm" element={<Jobseeker />} />
          <Route path="/dashboard/Investor" element={<InvestorDashboard />} />
          <Route path="/dashboard/Hustler" element={<HustlerDashboard />} />
          <Route path="/dashboard/cofounder" element={<CofunderDashboard />} />
          <Route path="/dashboard/jobSeeker" element={<JobseekerDashboard />} />
          <Route path="/videos" element={<VideosPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/talks" element={<TalksPage />} />
          <Route path="/Findcofounder" element={<Findcofounder />} />
          <Route path="/FindHustler" element={<FindHustler />} />
          <Route path="/FindInvestor" element={<FindInvestor />} />
          <Route path="/FindJobseeker" element={<FindJobseeker />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/Update_profile" element={<UpdateProfile />} />
          <Route path="/messages" element={<Messages />} />
          <Route path='/AboutUs' element={<AboutUs/>}/>
          <Route path='/Services' element={<Services/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
