import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { assets } from "../../assetes";

const CofounderForm = () => {
  const [formData, setFormData] = useState({
    Username: '',
    phoneno: '',
    bio: '',
    skills: [],
    dreamProject: '',
    superpower: '',
    profilePic: null,
  });
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "skills") {
      setFormData((prev) => ({ ...prev, skills: value.split(",").map(skill => skill.trim()) }));
    } else if (id === "profilePic") {
      const file = e.target.files[0];
      setFormData((prev) => ({ ...prev, profilePic: file }));
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setProfilePicPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formDataObj = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === "skills") {
        formDataObj.append(key, formData[key].join(","));
      } else if (key === "profilePic" && formData[key]) {
        formDataObj.append(key, formData[key]);
      } else {
        formDataObj.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/cofounder', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formDataObj,
      });

      const result = await response.json();
      if (response.ok) {
        alert('Cofounder profile updated successfully');
        navigate('/signin');
      } else {
        alert(result.message || 'Error updating profile');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };
  const handleSkip = () =>{
    navigate('/dashboard/Cofounder'); 
  }

  return (
    <div className="relative min-h-screen bg-gray-100 text-gray-800 flex flex-col items-center py-12 px-6">
      <div className="absolute top-0 left-0 m-6">
        <img src={assets.Logo} alt="datatreya Logo" className="h-12" />
      </div>
      <div className="absolute top-0 right-0 m-6">
      <button
        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300"
        onClick={handleSkip} // Ensure `handleSkip` navigates the user appropriately
      >
        Skip for Now
      </button>
    </div>
      <div className="w-full max-w-4xl">
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mt-20">Hello, Visionaries! 🌟</h1>
          <p className="text-lg text-gray-600">
            Ready to co-create something incredible with datatreya? create your profile and let’s see if you’re our perfect match!
          </p>
        </div>
        <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="col-span-2 flex flex-col items-center">
            {profilePicPreview ? (
              <img
                src={profilePicPreview}
                alt="Profile Preview"
                className="w-32 h-32 rounded-full object-cover border-4 border-blue-500"
              />
            ) : (
              <div className="w-32 h-32 rounded-full bg-blue-100 flex items-center justify-center text-gray-500">
                No Image
              </div>
            )}
            <input
              type="file"
              id="profilePic"
              className="mt-4 text-sm"
              accept="image/*"
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="Username">User Name</label>
            <input
              type="text"
              id="Username"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Your Full Name"
              value={formData.Username}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="phoneno">Phone Number</label>
            <input
              type="tel"
              id="phoneno"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="+000 0000000000"
              value={formData.phoneno}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="bio">Bio</label>
            <input
              type="text"
              id="bio"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Tell us something about yourself!"
              value={formData.bio}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="skills">Your Strengths</label>
            <input
              type="text"
              id="skills"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="You can add multiple skills separated by commas, e.g., Programming, Creativity"
              value={formData.skills.join(", ")}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="dreamProject">Your Vision</label>
            <input
              type="text"
              id="dreamProject"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="What’s your dream project?"
              value={formData.dreamProject}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="superpower">What Superpower Do You Have?</label>
            <input
              type="text"
              id="superpower"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Describe your superpower"
              value={formData.superpower}
              onChange={handleChange}
            />
          </div>
          <div className="col-span-2 text-left">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CofounderForm;
