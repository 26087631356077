import React, { useEffect, useState } from 'react';

const InvestorProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/Investorprofile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profiles');
        }

        const data = await response.json();
        setProfiles(data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [token]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen text-lg text-gray-600">
        <p>Loading investor profiles, please wait...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 mt-5">
        <h2 className="text-xl">Oops! Something went wrong.</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
<div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
  <header className="text-center py-10">
    <h1 className="text-4xl font-bold text-teal-400">Investors Hall of Fame</h1>
    <p className="text-gray-300 mt-4">
      Meet visionary investors eager to explore groundbreaking opportunities!
    </p>
  </header>

  <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    {profiles.length === 0 ? (
      <p className="text-center text-gray-400 col-span-full">
        No investors found yet!
      </p>
    ) : (
      profiles.map((profile) => {
        const {
          displayName,
          bio,
          profilePic,
          interestAreas,
          explorationPreference,
          excitement,
        } = profile.profiledetails;

        return (
          <div
            key={profile._id}
            className="bg-gray-700 rounded-lg p-6 hover:scale-105 transition-transform shadow-lg flex flex-col items-center text-center"
          >
            <img
              src={profilePic || '/default-avatar.png'}
              alt={`${displayName}'s profile`}
              className="w-24 h-24 rounded-full object-cover mb-4"
            />

            <h3 className="text-xl font-semibold text-teal-400">
              {displayName}
            </h3>

            <p className="text-sm text-gray-300 mt-2 italic">
              {bio || 'No bio available'}
            </p>

            <div className="mt-4">
              <p className="font-semibold text-teal-500">Interest Areas:</p>
              <ul className="list-disc pl-5 text-gray-300">
                {Array.isArray(interestAreas) && interestAreas.length > 0
                  ? interestAreas.map((area, index) => <li key={index}>{area}</li>)
                  : <li>No interest areas listed</li>}
              </ul>
            </div>

            <div className="mt-4 space-y-2">
              <p className="text-sm font-medium text-gray-300">
                <strong>Exploration Preference:</strong> {explorationPreference || 'Not specified'}
              </p>
              <p className="text-sm font-medium text-gray-300">
                <strong>Excitement:</strong> {excitement || 'Not specified'}
              </p>
            </div>

            <button className="mt-6 w-full bg-teal-500 hover:bg-teal-600 text-white font-semibold py-2 px-4 rounded-lg">
              Connect
            </button>
          </div>
        );
      })
    )}
  </div>
</div>
  );
};

export default InvestorProfiles;
