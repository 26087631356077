import React, { useState } from "react";
import axios from "axios";

const VideoUploadPopup = ({ isOpen, closePopup }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const validTypes = ["video/mp4", "video/mpeg", "video/quicktime", "video/x-msvideo"];

    if (selectedFile && !validTypes.includes(selectedFile.type)) {
      setError("Invalid file type! Please upload a valid video format. 🎥");
      setFile(null);
      return;
    }

    if (selectedFile && selectedFile.size > 50 * 1024 * 1024) {
      setError("File size exceeds the 50MB limit. Please upload a smaller video. 🎥");
      setFile(null);
      return;
    }

    setError(null);
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!title || !description || !file) {
      setError("Oops! All fields, including video, are required. 🛑");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("video", file);

    setIsLoading(true); // Start loading state

    try {
      const response = await axios.post(
        "https://dattatreya-back.onrender.com/api/userprofile/addvideo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data && response.data.message) {
        setMessage("🚀 Your video has been uploaded successfully! Time to inspire others.");
        setError(null);
        setTitle("");
        setDescription("");
        setFile(null);

        setTimeout(() => {
          closePopup();
        }, 2000);
      } else {
        setError("Something went wrong, please try again. 😓");
      }
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.error || "An unexpected error occurred while uploading your video. 😱"
      );
      setMessage(null);
    } finally {
      setIsLoading(false); 
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center animate-fadeIn">
      <div className="bg-white p-8 rounded-lg w-11/12 md:w-1/3 shadow-lg transform transition-transform duration-300 ease-in-out scale-105 hover:scale-100">
        <h2 className="text-3xl font-bold text-center text-teal-600 mb-6 tracking-wide">
          Share Posts about Your Brilliant Idea!
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <label className="block font-semibold text-gray-700">What's the title of your masterpiece? 🏆</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-3 border border-teal-300 rounded-md shadow-sm focus:ring-2 focus:ring-teal-400"
              placeholder="Enter the title of your video"
            />
          </div>
          <div className="mb-5">
            <label className="block font-semibold text-gray-700">Tell us about it! 📝</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-3 border border-teal-300 rounded-md shadow-sm focus:ring-2 focus:ring-teal-400"
              placeholder="Enter video description"
            />
          </div>
          <div className="mb-5">
            <label className="block font-semibold text-gray-700">Choose Your Video 🎥</label>
            <input
              type="file"
              onChange={handleFileChange}
              accept="video/mp4, video/mpeg, video/quicktime, video/x-msvideo"
              className="w-full p-3 border border-teal-300 rounded-md cursor-pointer"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full p-3 rounded-md transition duration-300 ${
              isLoading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-teal-500 text-white hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-300"
            }`}
          >
            {isLoading ? "Uploading..." : "Upload My Idea! 💡"}
          </button>
        </form>
        {error && <div className="mt-4 text-red-500 text-center font-medium">{error}</div>}
        {message && <div className="mt-4 text-green-500 text-center font-medium">{message}</div>}
        <button
          onClick={closePopup}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default VideoUploadPopup;
