import React, { useState } from 'react';
import { assets } from '../../assetes';
import { useNavigate } from 'react-router-dom';

const HustlerForm = () => {
  const [formData, setFormData] = useState({
    Username: '',
    phoneno: '',
    bio: '',
    project: '',
    currentStage: 'ideation',
    skills: [],
    currentWork: '',
    contribution: '',
    profilePic: null,
  });
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSkillChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      skills: checked
        ? [...prev.skills, value]
        : prev.skills.filter((skill) => skill !== value),
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, profilePic: file }));
      const reader = new FileReader();
      reader.onload = () => setProfilePicPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== 'profilePic') {
        form.append(key, formData[key]);
      }
    });
    if (formData.profilePic) {
      form.append('profilePic', formData.profilePic);
    }

    try {
      const response = await fetch('https://dattatreya-back.onrender.com/api/userprofile/Hustler', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });

      const result = await response.json();
      if (response.ok) {
        alert('Hustler profile updated successfully');
        navigate('/signin');
      } else {
        alert(result.message || 'Error updating profile');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };
  const handleSkip = () =>{
    navigate('/dashboard/Hustler'); 
  }

  return (
    <div className="relative min-h-screen bg-gray-100 text-gray-800 flex flex-col items-center py-12 px-6">
      <div className="absolute top-0 left-0 m-6">
        <img src={assets.Logo} alt="datatreya Logo" className="h-12" />
      </div>
      <div className="absolute top-0 right-0 m-6">
      <button
        className="bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300"
        onClick={handleSkip} 
      >
        Skip for Now
      </button>
    </div>
      <div className="w-full max-w-4xl">
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mt-20">Hey Hustler :)</h1>
          <p className="text-lg text-gray-600">
            Ready to make things happen with datatreya? Pls create your profile.
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded-lg shadow-md grid grid-cols-1 gap-6 sm:grid-cols-2"
        >
          <div className="col-span-2 flex flex-col items-center">
            {profilePicPreview ? (
              <img
                src={profilePicPreview}
                alt="Profile Preview"
                className="w-32 h-32 rounded-full object-cover border-4 border-blue-500"
              />
            ) : (
              <div className="w-32 h-32 rounded-full bg-blue-100 flex items-center justify-center text-gray-500">
                No Image
              </div>
            )}
            <input
              type="file"
              id="profilePic"
              className="mt-4 text-sm"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="Username">User Name</label>
            <input
              type="text"
              id="Username"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Your Full Name"
              value={formData.Username}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="phoneno">Phone Number</label>
            <input
              type="tel"
              id="phoneno"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="+000 0000000000"
              value={formData.phoneno}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="bio">Bio</label>
            <input
              type="text"
              id="bio"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Tell us something about yourself!"
              value={formData.bio}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="project">Your Project</label>
            <input
              type="text"
              id="project"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Company/Idea Name"
              value={formData.project}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="currentStage">Current Stage</label>
            <select
              id="currentStage"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.currentStage}
              onChange={handleChange}
            >
              <option value="ideation">Ideation</option>
              <option value="preSeed">Pre Seed</option>
              <option value="seed">Seed</option>
              <option value="seriesA">Series A</option>
              <option value="growth">Growth</option>
            </select>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="currentWork">What are you working on now?</label>
            <textarea
              id="currentWork"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="ex: I'm full-time working on this idea..."
              rows={4}
              value={formData.currentWork}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-600 mb-2" htmlFor="contribution">How are you contributing to your idea/Company?</label>
            <textarea
              id="contribution"
              className="w-full p-3 bg-blue-100 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Please describe your contributions..."
              rows={4}
              value={formData.contribution}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              What skills do you bring?
            </label>
            <div className="flex flex-wrap gap-4">
              {['Fast Execution', 'Goal-Oriented', 'Creative Problem-Solving', 'Networking'].map(
                (skill) => (
                  <label key={skill} className="inline-flex items-center">
                    <input
                      type="checkbox"
                      value={skill}
                      className="form-checkbox text-blue-600"
                      onChange={handleSkillChange}
                    />
                    <span className="ml-2">{skill}</span>
                  </label>
                )
              )}
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-3 px-6 bg-blue-600 text-white rounded-md shadow-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default HustlerForm;
