import React, { useState } from 'react';
import { assets } from '../assetes';
import { useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('Hustler');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true); 
    posthog.capture('signup_attempt', { method: 'email' });
    try {
      const response = await fetch('https://dattatreya-back.onrender.com/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          role,
        }),
      });

      if (response.ok) {
        alert('Signup successful!');
        const data = await response.json();
        localStorage.setItem('token', data.accessToken);
        console.log('Sign-Up successful', data);
        switch (role) {
          case 'Hustler':
            navigate('/HustlerForm');
            break;
          case 'Investor':
            navigate('/InvestorForm');
            break;
          case 'Cofounder':
            navigate('/CofunderForm');
            break;
          case 'Jobseeker':
            navigate('/JobseekerForm');
            break;
          default:
            navigate('/');
        }
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Signup failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false); 
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#8ab5f9] via-[#c9dcfc] to-white">
        <img
          src={assets.Mtbg}
          alt="background"
          className="absolute w-screen h-[401px] object-cover opacity-80 mt-96"
        />
        <div className="relative z-10">
          <div className="flex flex-col items-center">
            <img
              src="https://media.tenor.com/yjwvAUX_gcoAAAAj/funny-skeleton-dance.gif"
              alt="Dancing Skeleton"
              className="w-32 h-32"
            />
            <p className="mt-6 text-center text-lg text-gray-800 font-bold">
              "Hold tight! We're brewing something awesome ☕..."
            </p>
            <p className="mt-2 text-center text-gray-500">
              Your entrepreneurial journey is about to start! Loading...
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#8ab5f9] via-[#c9dcfc] to-white">
      <img 
        src={assets.Mtbg} 
        alt='background' 
        className="absolute w-screen h-[401px] object-cover opacity-80 mt-96"
      />
      <div className="absolute top-0 left-0 m-6">
        <img src={assets.Logo} alt="datatreya Logo" className="h-10" />
      </div>
      <div className="relative w-full max-w-lg bg-white p-8 rounded-lg shadow-md z-10">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-[#009ff5]">Sign Up</h1>
          <p className="text-gray-600">See what is going on with your business</p>
        </div>
        {errorMessage && (
          <div className="mb-4 text-red-500">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSignup}>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Email</label>
            <input
              type="email"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#009ff5]"
              placeholder="mail@abc.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Password</label>
            <input
              type="password"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[rgb(66,230,255)]"
              placeholder="*******"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Choose your role</label>
            <select
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[rgb(66,230,255)]"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="Hustler">Hustler</option>
              <option value="Investor">Investor</option>
              <option value="Cofounder">Cofounder</option>
              <option value="Jobseeker">Jobseeker</option>
            </select>
          </div>
          <div className="flex items-center justify-between mb-6">
            <a href="#" className="text-[#009ff5]">Forgot Password?</a>
          </div>
          <button type="submit" className="w-full bg-[#009ff5] text-white py-2 rounded-md hover:bg-[#0086d9]">
            Sign Up
          </button>
        </form>
        <div className="text-center mt-6">
          <p className="text-gray-600">
            Have an account?{' '}
            <a href="/signin" className="text-[#009ff5]">Sign In👈</a>
          </p>
        </div>
      </div>
      <div className="mt-6 text-center text-gray-500 relative z-10">
        <p>Copyright ©2025 Produced by datatreya & Co.</p>
      </div>
    </div>
  );
};

export default Signup;
