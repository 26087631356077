import React, { useState, useEffect } from "react";

const CofounderProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please login");
          return;
        }
        const response = await fetch(
          "https://dattatreya-back.onrender.com/api/userprofile/cofounderprofile",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch profiles.");
        }

        const result = await response.json();

        if (result.success) {
          const formattedProfiles = result.data.map((item) => ({
            id: item._id,
            name: item.profiledetails.Username,
            email: item.email,
            skills: Array.isArray(item.profiledetails.skills)
              ? item.profiledetails.skills
              : [],
            bio: item.profiledetails.bio,
            superpower: item.profiledetails.superpower,
            profilePic: item.profiledetails.profilePic , 
          }));
          setProfiles(formattedProfiles);
        } else {
          setError("No profiles found.");
        }
      } catch (err) {
        setError("An error occurred while fetching profiles.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  const filteredProfiles = profiles.filter(
    (profile) =>
      profile.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      profile.skills.some((skill) =>
        skill.toLowerCase().includes(searchQuery.toLowerCase())
      ) ||
      (profile.bio &&
        profile.bio.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <header className="text-center py-10">
        <h1 className="text-4xl font-bold text-teal-400">Meet Co-founders</h1>
        <p className="text-gray-300 mt-4">
          Discover individuals who share your entrepreneurial vision. Connect,
          collaborate, and create!
        </p>
      </header>

      <div className="max-w-4xl mx-auto px-4">
        <input
          type="text"
          placeholder="Search by name, skills, or bio"
          className="w-full p-3 rounded-lg border border-gray-600 bg-gray-700 text-white focus:ring-2 focus:ring-teal-400 outline-none mb-8"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 max-w-6xl mx-auto px-4">
        {loading ? (
          <p className="text-center text-gray-400">Loading profiles...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : filteredProfiles.length > 0 ? (
          filteredProfiles.map((profile) => (
            <div
              key={profile.id}
              className="bg-gray-700 rounded-lg p-6 hover:scale-105 transition-transform shadow-lg"
            >
              <div className="flex items-center mb-4">
                <img
                  src={profile.profilePic}
                  alt={`${profile.name}'s profile`}
                  className="w-12 h-12 rounded-full object-cover mr-4"
                />
                <div>
                  <h2 className="text-xl font-semibold text-teal-400">
                    {profile.name}
                  </h2>
                  <p className="text-gray-300 text-sm">Email: {profile.email}</p>
                </div>
              </div>
              <p className="text-sm text-gray-400 mt-2">
                Skills: {profile.skills.join(", ")}
              </p>
              {profile.superpower && (
                <p className="text-gray-300 text-sm mt-2">
                  Superpower: {profile.superpower}
                </p>
              )}
              <p className="text-gray-400 mt-4 text-sm">{profile.bio}</p>
              <button className="mt-6 w-full bg-teal-500 hover:bg-teal-600 text-white font-semibold py-2 px-4 rounded-lg">
                Connect
              </button>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-400">
            No profiles match your search criteria.
          </p>
        )}
      </div>
    </div>
  );
};

export default CofounderProfiles;
