import React from 'react';
import Hustlernavbar from '../../componentes/Hustlernavbar';
import Dashboard from '../../componentes/Dashboard';

const HustlerDashboard = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex-grow">
        <Dashboard />
      </div>
      <Hustlernavbar />
    </div>
  );
};

export default HustlerDashboard;
