import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { io } from "socket.io-client";

const socket = io("https://dattatreya-back.onrender.com/", {
  withCredentials: true,
  transports: ["websocket"],
});

const ChatPopup = ({ selectedUser }) => {
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const currentUserId = localStorage.getItem("userId");

  useEffect(() => {
    if (!selectedUser?._id) return;

    fetch(`https://dattatreya-back.onrender.com/api/messages/${selectedUser._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setMessages(data || []);
        console.log("Fetched Messages:", data);
      })
      .catch((error) => console.error("Error fetching messages:", error));
  }, [selectedUser]);

  useEffect(() => {
    if (!selectedUser?._id) return;

    socket.emit("join", currentUserId);

    socket.on("receiveMessage", (message) => {
      console.log("New message received:", message);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off("receiveMessage");
    };
  }, [selectedUser]);

  const handleSendMessage = () => {
    if (!newMessage.trim() || !selectedUser?._id) return;

    const messageData = {
      senderId: currentUserId,
      receiverId: selectedUser._id,
      text: newMessage,
    };

    // Emit message via socket
    socket.emit("sendMessage", messageData);

    // Update UI instantly
    setMessages((prevMessages) => [...prevMessages, messageData]);

    // Clear input field
    setNewMessage("");
  };

  return (
    <Draggable>
      <div className="fixed bottom-4 right-4 w-80 bg-white shadow-lg rounded-lg flex flex-col cursor-move">
        <div className="p-4 bg-indigo-600 text-white text-lg font-bold cursor-grab">
          Chat with {selectedUser?.profiledetails?.Username || selectedUser?.email?.split("@")[0]}
        </div>
        <div className="max-h-60 overflow-y-scroll p-4 flex flex-col space-y-2">
          {messages.length > 0 ? (
            messages.map((msg, index) => {
              const isSender = msg.senderId === currentUserId;
              return (
                <div key={index} className={`flex ${isSender ? "justify-end" : "justify-start"}`}>
                  <div
                    className={`p-2 max-w-xs rounded-lg shadow-md ${
                      isSender ? "bg-blue-500 text-white" : "bg-gray-300 text-black"
                    }`}
                  >
                    <p className="text-sm">{msg.text}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-gray-500 text-center text-lg">No messages yet</p>
          )}
        </div>
        <div className="p-3 border-t flex items-center">
          <input
            type="text"
            className="flex-1 p-2 border rounded-md"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <button onClick={handleSendMessage} className="bg-indigo-600 text-white px-4 py-2 ml-2 rounded-md">
            Send
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default ChatPopup;
