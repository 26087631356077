import React from "react";

const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="max-w-3xl bg-white p-8 rounded-2xl shadow-lg">
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-6">Dattatreya: Empowering Entrepreneurs & Investors</h1>
        <p className="text-lg text-gray-600 leading-relaxed">
          In a world full of ideas, execution is key. Dattatreya is not just a platform; it's a movement that transforms concepts into reality. We provide a space where creativity meets opportunity, bridging the gap between visionaries and investors.
        </p>
        <p className="text-lg text-gray-600 leading-relaxed mt-4">
          Imagine a place where your ideas get the visibility they deserve. A community where feedback refines your vision, and collaboration fuels success. At Dattatreya, we believe that innovation is a shared journey, and we are here to guide you every step of the way.
        </p>
        <p className="text-lg text-gray-600 leading-relaxed mt-4">
          For investors, Dattatreya offers a curated selection of innovative startups and projects. We streamline the discovery process, ensuring that promising ventures receive the support they need. Our platform provides key insights, data, and direct engagement opportunities, making investing in the next big idea easier than ever.
        </p>
        <p className="text-lg text-gray-600 leading-relaxed mt-4">
          Whether you're an aspiring entrepreneur, a seasoned investor, or someone passionate about groundbreaking solutions, Dattatreya welcomes you. Join us in shaping the future—one idea at a time.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
