import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TalksPage = () => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [comment, setComment] = useState({});
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://dattatreya-back.onrender.com/api/userprofile/getblogs', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.data.success) {
          setPosts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching posts:", error.message);
      }
    };

    fetchPosts();
  }, []);

  const handlePost = async () => {
    if (newPost.trim()) {
      try {
        const response = await axios.post(
          'https://dattatreya-back.onrender.com/api/userprofile/addTalks',
          { title: newPost },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        if (response.data.blog) {
          setPosts([response.data.blog, ...posts]);
          setNewPost('');
        }
      } catch (error) {
        console.error("Error posting talk:", error.message);
      }
    }
  };

  const handleComment = (postId, commentText) => {
    if (commentText.trim()) {
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId
            ? { ...post, comments: [...post.comments, { id: Date.now(), text: commentText }] }
            : post
        )
      );
      setComment((prev) => ({ ...prev, [postId]: '' }));
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-4">Talks</h2>
      <div className="mb-6">
        <textarea
          className="w-full p-3 border border-gray-300 rounded-lg"
          placeholder="What's on your mind?"
          value={newPost}
          onChange={(e) => setNewPost(e.target.value)}
        />
        <button
          className="mt-2 px-4 py-2 bg-teal-400 text-white rounded-lg hover:bg-teal-500"
          onClick={handlePost}
        >
          Post
        </button>
      </div>
      <div className="space-y-4">
        {posts.map((post) => (
          <div key={post._id} className="bg-gray-800 text-white p-4 rounded-lg">
            <p className="font-bold text-teal-400">{post.uploadedBy.username}</p>
            <p className="mt-2">{post.title}</p>
            <div className="mt-4 space-y-2">
              {post.comments.map((comment) => (
                <p key={comment.id} className="bg-gray-700 p-2 rounded-lg">
                  {comment.text}
                </p>
              ))}
              <div className="flex items-center space-x-2">
                <input
                  className="flex-1 p-2 rounded-lg bg-gray-700 text-white"
                  placeholder="Write a comment..."
                  value={comment[post._id] || ''}
                  onChange={(e) => setComment({ ...comment, [post._id]: e.target.value })}
                />
                <button
                  className="bg-teal-400 px-3 py-1 rounded-lg hover:bg-teal-500"
                  onClick={() => handleComment(post._id, comment[post._id] || '')}
                >
                  Comment
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TalksPage;
