import React from 'react';

const JobseekerDashboard = () => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Welcome to datatreya!</h1>
      <p>
        Thank you for your interest in exploring the Jobseeker features on our platform. 
        We truly value your enthusiasm and are excited about the potential of connecting talented individuals with opportunities.
      </p>
      <p>
        Currently, the Jobseeker features are not included in this prototype. 
        However, stay tuned as we are actively working to bring this vision to life in future updates!
      </p>
      <p>We appreciate your patience and support as we build something amazing together.</p>
    </div>
  );
};

export default JobseekerDashboard;
