import React from 'react'
import Dashboard from '../../componentes/Dashboard'
import Investornavbar from '../../componentes/InvestorNavbar'

const InvestorDashboard = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex-grow">
        <Dashboard />
      </div>
      <Investornavbar/>
    </div>
  )
}

export default InvestorDashboard