import React from 'react';
import { FaLightbulb, FaUsers, FaChartLine, FaHandshake, FaTools, FaRocket, FaMoneyBillWave, FaNetworkWired, FaGlobe } from 'react-icons/fa';

const features = [
  { 
    title: "Showcase Your Ideas", 
    text: "Create detailed profiles for your projects and gain visibility among investors and collaborators.", 
    color: "indigo-600", 
    icon: <FaLightbulb className="text-indigo-600 text-4xl" />
  },
  { 
    title: "Find Investors & Collaborators", 
    text: "Connect with a network of investors, mentors, and like-minded innovators to bring your ideas to life.", 
    color: "green-600", 
    icon: <FaUsers className="text-green-600 text-4xl" />
  },
  { 
    title: "Grow Your Vision", 
    text: "Access mentorship, funding, and market insights to scale your startup effectively.", 
    color: "purple-600", 
    icon: <FaChartLine className="text-purple-600 text-4xl" />
  },
  { 
    title: "Collaborative Ecosystem", 
    text: "Join a thriving community that fosters innovation through discussions, feedback, and partnerships.", 
    color: "blue-600", 
    icon: <FaHandshake className="text-blue-600 text-4xl" />
  },
  { 
    title: "Tools & Resources", 
    text: "Leverage cutting-edge tools, templates, and learning resources to refine and execute your ideas.", 
    color: "red-600", 
    icon: <FaTools className="text-red-600 text-4xl" />
  },
  { 
    title: "Launch & Scale", 
    text: "Get the support you need to take your project from an idea to a thriving business.", 
    color: "yellow-600", 
    icon: <FaRocket className="text-yellow-600 text-4xl" />
  },
  { 
    title: "Opportunities for Investors", 
    text: "Join datatreya as an investor and discover promising startups to support and scale.", 
    color: "teal-600", 
    icon: <FaMoneyBillWave className="text-teal-600 text-4xl" />
  },
  { 
    title: "Global Reach", 
    text: "Expand your network beyond borders and connect with innovators worldwide.", 
    color: "cyan-600", 
    icon: <FaGlobe className="text-cyan-600 text-4xl" />
  },
  { 
    title: "Tech & Infrastructure Support", 
    text: "Gain access to cloud services, AI tools, and cutting-edge tech solutions.", 
    color: "orange-600", 
    icon: <FaNetworkWired className="text-orange-600 text-4xl" />
  }
];

const Features = () => {
  return (
    <section className="mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 max-w-6xl mx-auto text-center px-4">
      {features.map((feature, index) => (
        <div 
          key={index} 
          className="p-6 bg-white rounded-lg shadow-md transform hover:scale-105 transition duration-300 flex flex-col items-center"
        >
          {feature.icon}
          <h3 className={`text-2xl font-bold mt-4 text-${feature.color}`}>{feature.title}</h3>
          <p className="mt-2 text-gray-600">{feature.text}</p>
        </div>
      ))}
    </section>
  );
};

export default Features;