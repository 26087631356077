import React, { useState, useEffect } from 'react';
import { FaTachometerAlt, FaRocket, FaBell, FaHandshake } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Cofoundernavbar = () => {
  const [profilePic, setProfilePic] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://dattatreya-back.onrender.com/api/userprofile/getUser', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.user.profiledetails.profilePic) {
          setProfilePic(data.user.profiledetails.profilePic);
        }
      })
      .catch((error) => {
        console.error('Error fetching profile data:', error);
      });
  }, []);

  return (
    <div>
      <div className="w-64 bg-gradient-to-b from-gray-800 to-gray-900 text-white p-5 sticky top-0 h-screen rounded-lg shadow-lg flex-shrink-0">
        <div className="mb-10 text-center">
          <h1 className="text-2xl font-bold uppercase tracking-wide text-teal-400">
            datatreya
          </h1>
        </div>
        <ul className="flex flex-col h-full">
          <div className="space-y-4 flex-1">
            <li
              className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all"
              onClick={() => navigate('/dashboard')}
            >
              <FaTachometerAlt className="text-teal-400" />
              <span>Dashboard</span>
            </li>
            <li
              className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all"
              onClick={() => navigate('/FindHustler')}
            >
              <FaRocket className="text-teal-400" />
              <span>Manage Startups</span>
            </li>
            <li
              className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all"
              onClick={() => navigate('/notifications')}
            >
              <FaBell className="text-teal-400" />
              <span>Notifications</span>
            </li>
            <li
              className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all"
              onClick={() => navigate('/networking')}
            >
              <FaHandshake className="text-teal-400" />
              <span>Networking</span>
            </li>
            <li
              className="flex items-center gap-3 hover:bg-gray-700 p-4 rounded-lg cursor-pointer transition-all"
              onClick={() => navigate('/profile')}
            >
              <div className="ml-2">
                {profilePic ? (
                  <img
                    src={profilePic}
                    alt="Profile"
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full bg-gray-600 flex items-center justify-center">
                    <span className="text-white text-xs">No Pic</span>
                  </div>
                )}
              </div>
              <span>Profile</span>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Cofoundernavbar;
