import React from 'react'

const Services = () => {
  return (
    <div className="min-h-screen p-6 bg-gray-100 text-gray-800 flex flex-col items-center">
    <div className="max-w-4xl text-center">
      <h2 className="text-3xl font-bold mb-4">How Dattatreya Works</h2>
      <p className="mb-6 text-lg">
        Welcome to the future of innovation! Signing up is simple—use your email and password, and choose a role.
        We have four roles: Hustler, Investor, Co-founder, and Job Seeker (not available in this prototype).
      </p>
      <p className="mb-6 text-lg">
        Once signed up, you can showcase your ideas, gain visibility among potential users, and understand your CTC & CPC
        at an early stage—when you have no money! Plus, you can connect with investors to bring your vision to life.
      </p>

      <h3 className="text-2xl font-semibold mt-6 mb-4">Roles in Dattatreya</h3>
      <div className="text-left">
        <div className="mb-4">
          <h4 className="text-xl font-bold">Hustler</h4>
          <p>
            The idea generator, the executor, the one who grinds to bring innovation to life.
            Hustlers pitch their ideas, gather feedback, and work towards making them a reality.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-xl font-bold">Investor</h4>
          <p>
            Investors fund and mentor promising startups. They discover groundbreaking ideas and help shape the future
            by supporting visionaries.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-xl font-bold">Co-founder</h4>
          <p>
            Co-founders join forces with hustlers to build startups from scratch, contributing skills, strategies,
            and experience to bring ideas to fruition.
          </p>
        </div>
        <div className="mb-4 opacity-50">
          <h4 className="text-xl font-bold">Job Seeker (Coming Soon)</h4>
          <p>
            Future team members looking for opportunities to work with startups and contribute their expertise.
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Services;